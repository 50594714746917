import { Formik, FormikHelpers, Form } from "formik";
import TextField from "../forms/TextField";
import Button from "../utils/Button";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import DateField from "../forms/DateField";
import ImageField from "../forms/ImageField";

export default function ModelForm(props: modelFormProps) {
  return (
    <Formik
      initialValues={props.model}
      onSubmit={props.onSubmit}
      validationSchema={Yup.object({
        name: Yup.string()
          .required("This field is required")
          .firstLetterUppercase(),
        releasedDate: Yup.date().nullable().required("This field is required"),
      })}
    >
      {(formikProps) => (
        <Form
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <TextField displayName="Name" field="name" />
          <DateField displayName="Released Date" field="releasedDate" />
          <ImageField
            displayName="Picture"
            field="picture"
            imageURL={props.model.pictureURL}
          ></ImageField>
          <Button disabled={formikProps.isSubmitting} type="submit">
            {" "}
            Save Changes
          </Button>
          <Link to="/models" className="btn btn-secondary">
            Cancel
          </Link>
        </Form>
      )}
    </Formik>
  );
}
interface modelFormProps {
  model: modelCreationDTO;
  onSubmit(
    values: modelCreationDTO,
    action: FormikHelpers<modelCreationDTO>
  ): void;
}
