import CategoryForm from "./CategoryForm";

export default function CreateCategory() {
  //history useHistory is not availble to dom6 new is useNavigate
  return (
    <>
      <h3>Create Product Category</h3>
      <CategoryForm
        model={{ name: "" }}
        onSubmit={async (value) => {
          //when form is posted
          await new Promise((r) => setTimeout(r, 1));
          console.log(value);
        }}
      />
    </>
  );
}
