import ManufacturerForm from "./ManufacturerForm";

export default function EditManufacturer() {
  return (
    <>
      <h3>Edit Manufacturer</h3>
      <ManufacturerForm
        model={{ name: "Apple Inc." }}
        onSubmit={async (value) => {
          await new Promise((r) => setTimeout(r, 1));
          console.log(value);
        }}
      />
    </>
  );
}
