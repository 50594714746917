import { useParams } from "react-router-dom";
import CategoryForm from "./CategoryForm";

export default function EditCategory() {
  const { id }: any = useParams();
  return (
    <>
      <h3>Edit Product Category</h3>
      <CategoryForm
        model={{ name: "Action" }}
        onSubmit={async (value) => {
          //when form is posted
          await new Promise((r) => setTimeout(r, 1));
          console.log(id);
          console.log(value);
        }}
      />
    </>
  );
}
