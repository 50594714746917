import { NavLink } from "react-router-dom";

export default function Menu() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
          Chekos Gadtech
        </NavLink>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className="nav-link" to="/categories">
                Categories
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/manufacturers">
                Manufactures
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/brands">
                Brands
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/models">
                Models
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/variants">
                Variants
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/products/filter">
                Filter Products
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/products/create">
                Create a Product
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
