import { date } from "yup";
import ModelForm from "./ModelForm";

export default function EditModel() {
  return (
    <>
      <h3>Edit Model</h3>
      <ModelForm
        model={{
          name: "iPhone 15 Pro Max",
          releasedDate: new Date("2023-01-02T00:00:00"),
          pictureURL:
            "https://store.storeimages.cdn-apple.com/1/as-images.apple.com/is/iphone-15-model-unselect-gallery-2-202309?wid=5120&hei=2880&fmt=webp&qlt=70&.v=aVFiZEF4WDEvUWJNSU5HRDg4cklnTGdzSmpObkZCM3MrNmJ5SkhESlNDaU00NGE1MW1TNWM0aU1qbjRuMkdmMUpFd0xhWDVibStLdGRYRmxkNGI4VTdpMGJRT0ppMjh4RlRZQkc0Q3FZZENvSENINmdsRnQ5dUdQR0RpRENOcVA=&traceId=1",
        }}
        onSubmit={(values) => console.log(values)}
      />
    </>
  );
}
