import { productDTO } from "./products.model";
import css from "./IndividualProduct.module.css";

export default function IndividualProduct(props: productDTO) {
  const buildLink = () => `/product/${props.id}`;

  return (
    <div className={css.div}>
      <a href={buildLink()}>
        <img alt="Poster" src={props.poster} />
      </a>
      <p>
        <a href={buildLink()}>{props.model}</a>
      </p>
    </div>
  );
}
