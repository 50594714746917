import BrandForm from "./BrandForm";

export default function EditBrand() {
  return (
    <>
      <h3>Edit Brand</h3>
      <BrandForm
        model={{ name: "iPhone" }}
        onSubmit={async (value) => {
          //when form is posted
          await new Promise((r) => setTimeout(r, 1));
          console.log(value);
        }}
      />
    </>
  );
}
