import { useEffect, useState } from "react";
import ProductList from "./ProductList";
import { landingPageDTO } from "./products.model";

export default function LandingPage() {
  const [products, setProducts] = useState<landingPageDTO>({});

  useEffect(() => {
    const timerId = setTimeout(() => {
      setProducts({
        available: [
          {
            id: 1,
            model: "iPhone 15",
            poster:
              "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/IPhone_15_Vector.svg/220px-IPhone_15_Vector.svg.png",
          },
          {
            id: 2,
            model: "iPhone 13",
            poster:
              "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/IPhone_15_Vector.svg/220px-IPhone_15_Vector.svg.png",
          },
          {
            id: 3,
            model: "iPhone 12",
            poster:
              "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/IPhone_15_Vector.svg/220px-IPhone_15_Vector.svg.png",
          },
        ],
        unAvailbale: [
          {
            id: 1,
            model: "iPhone XR",
            poster:
              "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/IPhone_15_Vector.svg/220px-IPhone_15_Vector.svg.png",
          },
        ],
      });
    }, 1000);

    return () => clearTimeout(timerId);
  });

  return (
    <>
      <h3>Available Products</h3>
      <ProductList products={products.available} />

      <h3>Unavailable Products</h3>
      <ProductList products={products.unAvailbale} />
    </>
  );
}
