import { Link } from "react-router-dom";
import Button from "../utils/Button";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import TextField from "../forms/TextField";
import { productCreationDTO } from "./products.model";

export default function ProductForm(props: productFormProps) {
  return (
    <Formik
      initialValues={props.model}
      onSubmit={props.onSubmit}
      validationSchema={Yup.object({
        name: Yup.string()
          .required("This field is required")
          .firstLetterUppercase(),
      })}
    >
      {(formikProps) => (
        <Form
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <TextField field="name" displayName="Name" />

          <Button disabled={formikProps.isSubmitting} type="submit">
            Save Changes
          </Button>

          <Link className="btn btn-primary" to="/products">
            Cancel
          </Link>
        </Form>
      )}
    </Formik>
  );
}

interface productFormProps {
  model: productCreationDTO;
  onSubmit(
    values: productCreationDTO,
    action: FormikHelpers<productCreationDTO>
  ): void;
}
