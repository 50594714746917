import { Link } from "react-router-dom";

export default function IndexModels() {
  return (
    <>
      <h3>Models</h3>
      <Link className="btn btn-primary" to="/models/create">
        Create Models{" "}
      </Link>
    </>
  );
}
