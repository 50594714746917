import ManufacturerForm from "./ManufacturerForm";

export default function CreateManufacturer() {
  return (
    <>
      <h3>Create Manufacturer</h3>
      <ManufacturerForm
        model={{ name: "" }}
        onSubmit={async (value) => {
          await new Promise((r) => setTimeout(r, 1));
          console.log(value);
        }}
      />
    </>
  );
}
