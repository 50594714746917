import { Form, Formik, FormikHelpers } from "formik";
import { manufacturerCreationDTO } from "./manufacturer.model";
import * as Yup from "yup";
import TextField from "../forms/TextField";
import Button from "../utils/Button";
import { Link } from "react-router-dom";

export default function ManufacturerForm(props: manufacturerFormProps) {
  return (
    <Formik
      initialValues={props.model}
      onSubmit={props.onSubmit}
      validationSchema={Yup.object({
        name: Yup.string()
          .required("This field is required")
          .firstLetterUppercase(),
      })}
    >
      {(formikProps) => (
        <Form
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <TextField field="name" displayName="Name" />
          <Button disabled={formikProps.isSubmitting} type="submit">
            Save Changes
          </Button>

          <Link className="btn btn-primary" to="/categories">
            Cancel
          </Link>
        </Form>
      )}
    </Formik>
  );
}

interface manufacturerFormProps {
  model: manufacturerCreationDTO;
  onSubmit(
    values: manufacturerCreationDTO,
    action: FormikHelpers<manufacturerCreationDTO>
  ): void;
}
