import ProductForm from "./CreateProductForm";

export default function CreateProduct() {
  return (
    <>
      <h3>Create Product</h3>
      <ProductForm
        model={{ name: "" }}
        onSubmit={async (value) => {
          //when form is posted
          await new Promise((r) => setTimeout(r, 1));
          console.log(value);
        }}
      />
    </>
  );
}
