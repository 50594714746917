import { Link } from "react-router-dom";

export default function IndexManufacturers() {
  return (
    <>
      <h3>Manufacturers</h3>
      <Link className="btn btn-primary" to="/manufacturers/create">
        Create Manufacturer{" "}
      </Link>
    </>
  );
}
