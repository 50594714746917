import ProductForm from "./CreateProductForm";

export default function EditProduct() {
  return (
    <>
      <h3>Edit Product</h3>
      <ProductForm
        model={{ name: "Apple iPhone 15 Pro Max 128GB Silver SN: 10001" }}
        onSubmit={async (value) => {
          //when form is posted
          await new Promise((r) => setTimeout(r, 1));
          console.log(value);
        }}
      />
    </>
  );
}
