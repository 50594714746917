import { productDTO } from "./products.model";
import css from "./ProductList.module.css";
import IndividualProduct from "./IndividualProduct";
import GenericList from "../utils/GenericList";

export default function ProductList(props: productListProps) {
  return (
    <GenericList list={props.products}>
      <div className={css.div}>
        {props.products?.map((product) => (
          <IndividualProduct {...product} key={product.id} />
        ))}
      </div>
    </GenericList>
  );
}
interface productListProps {
  products?: productDTO[];
}
