import VariantForm from "./VariantForm";

export default function EditVariant() {
  return (
    <>
      <h3>Edit Variant</h3>

      <VariantForm
        model={{ name: "Apple iPhone 15 Pro Max 128GB Silver" }}
        onSubmit={async (value) => {
          //when form is posted
          await new Promise((r) => setTimeout(r, 1));
          console.log(value);
        }}
      />
    </>
  );
}
