import ModelForm from "./ModelForm";

export default function CreateModel() {
  return (
    <>
      <h3>Create Model</h3>
      <ModelForm
        model={{ name: "", releasedDate: undefined }}
        onSubmit={(values) => console.log(values)}
      />
    </>
  );
}
