import "./App.css";
import Menu from "./Menu";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./route-config";
import configureValidations from "./Validations";

configureValidations();

function App() {
  /*
For BrowserRouter:
npm i react-router-dom
and
npm i @types/react-router-dom

Not using <Switch since im using DOM 6

    */
  return (
    <BrowserRouter>
      <Menu />
      <div className="container">
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<route.component />}
            ></Route>
          ))}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
