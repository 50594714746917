import { Component } from "react";
import CreateBrand from "./brands/CreateBrand";
import EditBrand from "./brands/EditBrand";
import IndexBrands from "./brands/IndexBrands";
import CreateCategory from "./categories/CreateCategory";
import EditCategory from "./categories/EditCategory";
import IndexCategories from "./categories/IndexCategories";
import CreateManufacturer from "./manufacturers/CreateManufacturer";
import EditManufacturer from "./manufacturers/EditManufacturer";
import IndexManufacturers from "./manufacturers/IndexManufacturers";
import CreateModel from "./models/CreateModel";
import EditModel from "./models/EditModel";
import IndexModels from "./models/IndexModels";
import CreateProduct from "./products/CreateProduct";
import EditProduct from "./products/EditProduct";
import FilterProducts from "./products/FilterProducts";
import LandingPage from "./products/LandingPage";
import CreateVariant from "./variants/CreateVariant";
import EditVariant from "./variants/EditVariant";
import IndexVariants from "./variants/IndexVariants";
import RedirectToLandingPage from "./utils/RedirectToLandingPage";

const routes = [
  { path: "/categories", component: IndexCategories },
  { path: "/categories/create", component: CreateCategory },
  { path: "/categories/edit/:id", component: EditCategory },

  { path: "/manufacturers", component: IndexManufacturers },
  { path: "/manufacturers/create", component: CreateManufacturer },
  { path: "/manufacturers/edit/:id", component: EditManufacturer },

  { path: "/categories", component: IndexCategories },
  { path: "/categories/create", component: CreateCategory },
  { path: "/categories/edit/:id", component: EditCategory },

  { path: "/brands", component: IndexBrands },
  { path: "/brands/create", component: CreateBrand },
  { path: "/brands/edit/:id", component: EditBrand },

  { path: "/models", component: IndexModels },
  { path: "/models/create", component: CreateModel },
  { path: "/models/edit/:id", component: EditModel },

  { path: "/variants", component: IndexVariants },
  { path: "/variants/create", component: CreateVariant },
  { path: "/variants/edit/:id", component: EditVariant },

  { path: "/products/filter", component: FilterProducts },
  { path: "/products/create", component: CreateProduct },
  { path: "/products/edit/:id", component: EditProduct },

  { path: "/", component: LandingPage },
  { path: "*", component: RedirectToLandingPage },
];

export default routes;
