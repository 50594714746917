import { Link } from "react-router-dom";

export default function IndexCategories() {
  return (
    <>
      <h3>Categories</h3>
      <Link className="btn btn-primary" to="/categories/create">
        Create Product Category{" "}
      </Link>
    </>
  );
}
