import { Field, Formik, Form } from "formik";
import { categoryDTO } from "../categories/category.model";
import Button from "../utils/Button";

export default function FilterProducts() {
  const initialValues: filterProductForm = {
    name: "",
    categoryId: 0,
    available: false,
  };

  const categories: categoryDTO[] = [
    { id: 1, name: "Smartphone" },
    { id: 2, name: "Laptop" },
  ];

  return (
    <>
      <h3>Filter Product</h3>;
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => console.log(values)}
      >
        {(formikProps) => (
          <Form
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <div className="row gx-3 align-items-center">
              <div className="col-auto">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name of Product"
                  {...formikProps.getFieldProps("name")}
                />
              </div>
              <div className="col-auto">
                <select
                  className="form-select"
                  {...formikProps.getFieldProps("categoryId")}
                >
                  <option value="0">--Choose a category--</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-auto">
                <div className="form-check">
                  <Field
                    className="form-check-input"
                    id="available"
                    name="available"
                    type="checkbox"
                  />
                  <label className="form-check-label" htmlFor="available">
                    Available
                  </label>
                </div>
              </div>
              <div className="col-auto">
                <Button
                  className="btn btn-primary"
                  onClick={() => formikProps.submitForm()}
                >
                  Filter
                </Button>

                <Button
                  className="btn btn-danger ms-3"
                  onClick={() => formikProps.setValues(initialValues)}
                >
                  Clear
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

interface filterProductForm {
  name: string;
  categoryId: number;
  available: boolean;
}
